import DeliveryPeriod from "domain/deliveryPeriod";
import ThrowsValidationError from 'common/http/throwsValidationError';
import { AxiosError } from 'axios';
import DeliveryPeriodsBulkUploadResponse from './uploadDeliveryPeriodsResponse';
import ValidationError from 'common/validationError';
import ReturnsNullOn404 from 'common/http/returnsNullOn404';
import HttpService from "common/http/httpService";

class DeliveryPeriodService extends HttpService {
    async listDeliveryPeriods(commodityId?: number, locationId?: string, includeBidEditorOnly?: boolean): Promise<{ deliveryPeriods: DeliveryPeriod[], lastUpdatedBy: string, lastUpdatedDate: Date }> {
        const response = await this.getClient().get<{ deliveryPeriods: DeliveryPeriod[], lastUpdatedBy: string, lastUpdatedDate: Date }>('/settings/v1/delivery-periods', {
            params: {
                commodityId,
                locationId,
                includeBidEditorOnly
            }
        });
        return response.data;
    }

  @ReturnsNullOn404()
  async getDeliveryPeriod(deliveryPeriodId: string): Promise<DeliveryPeriod> {
    const response = await this.getClient().get<DeliveryPeriod>(`/settings/v1/delivery-periods/${deliveryPeriodId}`);
    return response.data;
  }

    @ThrowsValidationError()
    async createDeliveryPeriod(deliveryPeriod: DeliveryPeriod): Promise<DeliveryPeriod> {
        const request = { 
            futuresContractId: deliveryPeriod?.contract?.id,
            cropYearId: deliveryPeriod.cropYear.id, 
            name: deliveryPeriod.name, 
            erpName: deliveryPeriod.erpName,
            start: deliveryPeriod.startString,
            end: deliveryPeriod.endString,
            htaFee: deliveryPeriod.htaFee, 
            premium: deliveryPeriod.premium,  
            bids: deliveryPeriod.bids,
            commodityId: deliveryPeriod.commodity.id,
            visibility: deliveryPeriod.visibility,
            isSpot: deliveryPeriod.isSpot
        };
        const response = await this.getClient().post<DeliveryPeriod>("/settings/v1/delivery-periods", request);
        return response.data;
    }

    @ThrowsValidationError()
    async updateDeliveryPeriod(deliveryPeriod: DeliveryPeriod): Promise<any> {
        const request = { 
            futuresContractId: deliveryPeriod?.contract?.id, 
            cropYearId : deliveryPeriod.cropYear.id, 
            name: deliveryPeriod.name,
            erpName: deliveryPeriod.erpName,
            start: deliveryPeriod.startString,
            end: deliveryPeriod.endString,
            htaFee: deliveryPeriod.htaFee, 
            premium: deliveryPeriod.premium,
            bids : deliveryPeriod.bids,
            commodityId: deliveryPeriod.commodity.id,
            visibility: deliveryPeriod.visibility,
            isSpot: deliveryPeriod.isSpot
        };
        await this.getClient().put<DeliveryPeriod>(`/settings/v1/delivery-periods/${deliveryPeriod.id}`, request);
    }

    @ThrowsValidationError()
    async deleteDeliveryPeriod(deliveryPeriod: DeliveryPeriod): Promise<any> {
        await this.getClient().delete<String>(`/settings/v1/delivery-periods/${deliveryPeriod.id}`);
    }

    async uploadDeliveryPeriods(selectedFile: any): Promise<any> {
        try {
          let formData = new FormData();
          formData.append("formFile", selectedFile)
          const response = await this.getClient().post<any>(
            '/settings/v1/delivery-periods/validate-bulk-import', formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          );
          return response.data;
        }
        catch (error) {
          const response = (error as AxiosError).response;
          if (response.status === 400) {
            const payload: any = response.data;
            if (payload.errorType != null || payload.errors != null) {
              throw new ValidationError(payload.errors)
            }
            else {
              return payload;
            }
          }
          return response.data;
        }
      }
    
      async importDeliveryPeriods(selectedFile: any): Promise<any> {
        try {
          let formData = new FormData();
          formData.append("formFile", selectedFile)
          const response = await this.getClient().post<DeliveryPeriodsBulkUploadResponse>(
            '/settings/v1/delivery-periods/bulk-import', formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          );
          return response.data;
        }
        catch (error) {
          const response = (error as AxiosError).response;
          if (response.status === 400) {
            const payload: any = response.data;
            if (payload.errorType != null || payload.errors != null) {
              throw new ValidationError(payload.errors)
            }
            else {
              return payload;
            }
          }
        }
      }
}
const deliveryPeriodService = new DeliveryPeriodService();
export default deliveryPeriodService;